import { Flex, Image, Stack, Text } from '@chakra-ui/react'
import ImageFile from '@config/constant/image'
import AUTH_LABELS from '@data/localization/auth'
import useLang from '@hooks/useLang'
const Logo = () => {
  const { lang } = useLang()
  return (
    <Flex
      direction={{
        base: 'column',
        sm: 'row',
      }}
      gap='4'
      justifyContent={{ base: 'center', sm: 'center' }}
      alignItems={{ base: 'center', sm: 'center' }}
    >
      <Image width={{ base: '70px' }} objectFit='cover' src={ImageFile?.logo} alt='logo' />
      <Stack direction='column' spacing={1} textAlign={{ base: 'center', sm: 'center' }}>
        <Text fontSize='.9rem' fontWeight='500'>
          {AUTH_LABELS?.governmentOfNepal[lang]}
        </Text>
        <Text fontSize='1rem' fontWeight='500'>
          {AUTH_LABELS?.nagarpalikaServiceDeliveryApp[lang]}
        </Text>
        <Text fontSize='.9rem' fontWeight='500'>
          {AUTH_LABELS?.nagarpalikaName[lang]}
        </Text>
      </Stack>
    </Flex>
  )
}

export default Logo
