import { Box, Container, Heading } from "@chakra-ui/react";
import CaptionCarousel from "@ui/user/organisms/Carousel";
import Feature from "@ui/user/organisms/Feature";
import Hero from "@ui/user/organisms/Hero";
import VideoGallery from "@ui/user/organisms/VideoGallery";
import ImageGalleryList from "../organisms/ImageGallery";

const index = () => {
  return (
    <Box>
      <CaptionCarousel />
      <Hero />
      <Feature />
      <Container py="5" mt="5">
        <Heading
          fontSize={{ base: "2xl", sm: "3xl" }}
          textAlign="center"
          py="2"
          fontWeight={"bold"}
        >
          Video Gallery
        </Heading>
        <VideoGallery buttonAction="link" hideSearch />

        <Box pt="5">
          <Heading
            fontSize={{ base: "2xl", sm: "3xl" }}
            fontWeight={"bold"}
            textTransform="capitalize"
          >
            Image Gallery{" "}
          </Heading>
          <ImageGalleryList buttonAction="link" hideSearch />
        </Box>
      </Container>
    </Box>
  );
};

export default index;
