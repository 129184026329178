import { Editor } from "@tinymce/tinymce-react";
import { useEffect, useState } from "react";

interface IProps {
  value: any;
  error?: boolean;
  height?: number;
  handleChange: Function;
  placeholder: string;
}

const TextEditor = (props: IProps) => {
  const [value, setValue] = useState<string>(props.value ?? "");

  useEffect(() => {
    props.handleChange(value);
  }, [value]);

  return (
    <>
      <Editor
        // apiKey={import.meta.env.VITE_TEXT_EDITOR_API_KEY}
        apiKey="ef516cdp6qxttehxf1qtw2t4kpzcod2pqqf60h53cy2m1v05"
        value={value}
        onEditorChange={(e, _editor) => {
          setValue(e);
        }}
        init={{
          height: props?.height ?? 500,
          menubar: false,
          plugins: [
            "advlist autolink lists link image charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code help wordcount",
          ],
          toolbar:
            "undo redo formatselect h1 h2 h3 h4 h5 h6 underline" +
            " bold italic alignleft aligncenter " +
            "alignright alignjustify bullist numlist outdent indent selectall copy cut",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        }}
      />
    </>
  );
};

export default TextEditor;
