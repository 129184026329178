import {
  Box,
  Container,
  Divider,
  Flex,
  Heading,
  Icon,
  IconProps,
  Image,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import image from "@config/constant/image";

export default function Hero() {
  return (
    <Container>
      <Stack
        align={"center"}
        spacing={{ base: 8, md: 10 }}
        py={{ base: 20, md: 28 }}
        direction={{ base: "column", md: "row" }}
      >
        <Stack spacing={{ base: 5, md: 10 }}>
          <Heading lineHeight={1.1}>
            <Text fontSize={"30px"} pt="1">
              फिदिमको नाम्बिन टोलका कृषकहरुलाई कृषि सामग्री र बिउबिजन वितरण
            </Text>
          </Heading>
          <Divider />
          <Text fontSize={"22px"} color={"gray.500"}>
            फिदिम नगरपालिका–६ सिवास्थित नाम्बिन टोलका कृषकहरुलाई तरकारी खेतीका
            लागि टनेल, ड्रम, पाइप र बिउबिजन वितरण गरिएको छ । फिदिम नगरपालिकाको
            सहयोगमा स्थानीय नाम्बिन कृषक समूहले २२ घरधुरी कृषकहरुलाई ७५ प्रतिशत
            अनुदानमा उक्त सामग्रीहरु वितरण गरेको हो ।
          </Text>
        </Stack>
        <Flex
          justify={"center"}
          align={"center"}
          position={"relative"}
          w={"full"}
        >
          <Blob
            w={"100%"}
            h={"100%"}
            position={"absolute"}
            top={"-20%"}
            left={0}
            zIndex={-1}
            color={useColorModeValue("red.50", "red.400")}
          />
          <Box
            position={"relative"}
            height={"400px"}
            rounded={"2xl"}
            boxShadow={"2xl"}
            width={"full"}
            overflow={"hidden"}
          >
            <Image
              opacity={0.75}
              alt={"Hero Image"}
              fit={"cover"}
              align={"center"}
              w={"100%"}
              h={"100%"}
              src={image.hero}
            />
          </Box>
        </Flex>
      </Stack>
    </Container>
  );
}

export const Blob = (props: IconProps) => {
  return (
    <Icon
      width={"100%"}
      viewBox="0 0 578 440"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M239.184 439.443c-55.13-5.419-110.241-21.365-151.074-58.767C42.307 338.722-7.478 282.729.938 221.217c8.433-61.644 78.896-91.048 126.871-130.712 34.337-28.388 70.198-51.348 112.004-66.78C282.34 8.024 325.382-3.369 370.518.904c54.019 5.115 112.774 10.886 150.881 49.482 39.916 40.427 49.421 100.753 53.385 157.402 4.13 59.015 11.255 128.44-30.444 170.44-41.383 41.683-111.6 19.106-169.213 30.663-46.68 9.364-88.56 35.21-135.943 30.551z"
        fill="currentColor"
      />
    </Icon>
  );
};
